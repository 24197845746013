:root {
    --background-color: #fff;
    --text-color: #000;
    --blue-color: rgb(76, 128, 240);
    --background-color-selected-skill: #ecebebcb;
    --background-color-project-description: #1B1B1B;
    --text-color-project-description: #fff;
    --icon-project-color: #000;
    --border-color-other-project: #535353;
}

.dark-mode {
    --background-color: #000;
    --text-color: #fff;
    color: #fff;
    --background-color-selected-skill: #2b2b2bcb;
    --icon-project-color: #fff;
    --background-color-project-description: rgb(76, 128, 240);
    --text-color-project-description: rgb(255, 255, 255);
    --border-color-other-project: #cccccc;
    /* --background-color-project-description: #fff;
    --text-color-project-description: #000; */
}


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Roboto', sans-serif;
    max-width: 950px;
    margin: auto;
    background-color: var(--background-color);
}

.section {
    padding: 20px;
    font-size: 22px;
    margin-bottom: 4em;
}

.section h2 {
    margin-bottom: 1em;
}

.md {
    margin-right: auto;
}

.link {
    text-decoration: none;
}

/* Header */

header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    padding: 20px;
    background-color: var(--background-color);
    width: 100%;
    max-width: 950px;
    position: fixed;
    top: 0;
    z-index: 15;
    
}

header h2, .logo {
    color: var(--text-color);
    margin-right: auto;
    font-size: 26px;
    text-decoration: none;
}

header h2:hover, .logo {
    cursor: pointer;
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style-type: none;
    padding: 10px;
}

.active {
    visibility: visible;
    transition: all 0.5s;
}

.hidden {
    visibility: hidden;
    transition: all 0.2s;
    transform: translateY(-100%);
}

.nav>li, .nav-link {
    color: var(--text-color);
    padding: 10px; 
    text-decoration: none;
}

.nav>li:hover, .nav-link:hover {
    cursor: pointer;
    opacity: 90%;
    color: var(--blue-color);
}


/* Intro */

.intro {
    padding: 20px;
    margin-top: 40vh;
    margin-bottom: 40vh;
    margin-left: 15vw;
    font-size: 30px;
}

.intro h1, .intro h3 {
    margin-bottom: 10px;
}

.intro h5 {
    font-size: 18px;
    margin-top: 1em;
    font-weight: 300;
}

.intro h4 {
    font-size: 20px;
}

/* SKill */

.skill-item ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
}

.skill-item li {
    font-size: 16px;
    padding: 15px 35px 15px 35px;
    line-height: 150%;
    text-align: center;   
}

.selected-skill {
    background-color: var(--background-color-selected-skill);
    border-radius: 15px 15px 0 0;
}


.skill-item li:hover {
    background-color: var(--background-color-selected-skill);
    border-radius: 15px 15px 0 0;
    transition:  0.2s;
    cursor: pointer;
}

/* Skill Details */

.skill-details {
    display: flex; 
    flex-direction: row;
    justify-content: center;
    text-align: center;
    /* max-width: 705px;  */
    max-width: 660px;
    margin: auto;
    padding-top: 1em;
    font-size: 16px;
    border-top: 2px solid var(--blue-color);
}



.one-skill {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    padding: 1em; 
    -webkit-animation: scale 0.5s ease-in-out;
    animation: scale 0.3s ease-in-out;
}

@keyframes scale {
    0% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.devicon-git-plain {
    max-width: 2em;
    margin-bottom: 1em;  
}

/* Projects */

.one-project {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-left: 3em;
    margin-top: 2em;
}

.one-project-left {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-right: 3em;
    margin-top: 6em;
}

.one-project:hover .project-description {
    transform: translateX(-10px);
}

.one-project-left:hover .project-description-left {
    transform: translateX(10px);
}

.project-thumb {
    max-width: 450px;
    border-radius: 4px;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
}

#speedype-img {
    border-radius: 4px;
}

.overlay {
    /* background-color: #4682b4; */
    opacity: 0.2;
    transition: 0.2s;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
}

.overlay:hover {
    opacity: 0.2;
    transition: 0.2s;
    background-color: #4682b4;
}

.project-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-end;
    align-items: flex-end;
    text-align: right;
    position: absolute;
    /* width: 100%;
    max-width: 40%; */
    left: 40%;
    /* margin-right: 1em;
    margin-left: auto; */
}

.project-info-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    text-align: left;
    position: absolute;
    /* width: 100%;
    max-width: 40%; */
    right: 40%;
    /* margin-right: 1em;
    margin-left: auto; */
}

.project-featured {
    color: var(--blue-color);
    font-size: 16px;
    margin-bottom: 0.5em;
}

.project-title {
    color: var(--text-color);
    margin-bottom: 1em;
    text-decoration: none;
    font-size: 26px;
    font-weight: bold;
    text-align: right;
    margin-left: auto;
}

.project-title-left {
    color: var(--text-color);
    margin-bottom: 1em;
    text-decoration: none;
    font-size: 26px;
    font-weight: bold;
    text-align: left;
    margin-right: auto;
}

.project-description {
    font-size: 16px;
    background-color: var(--background-color-project-description);
    color: var(--text-color-project-description);
    border-radius: 10px;
    padding: 1.3em 1.3em 1.3em 4em;
    margin-bottom: 1em;
    margin-right: auto;
    line-height: 150%;
    min-height: 60px;
    box-shadow: 3px 3px 10px -3px #000;
    transition: ease-in-out 0.2s;
    transform: translateX(10px);
    z-index: 2;
}

.project-description-left {
    font-size: 16px;
    background-color: var(--background-color-project-description);
    color: var(--text-color-project-description);
    border-radius: 10px;
    padding: 1.3em 4em 1.3em 1.3em;
    margin-bottom: 1em;
    margin-left: auto;
    line-height: 150%;
    min-height: 60px;
    box-shadow: 3px 3px 10px -3px #000;
    transition: ease-in-out 0.2s;
    transform: translateX(-10px);
    z-index: 2;
}

.project-description:hover {
    cursor: default;
    transition: ease-in-out 0.2s;
    transform: translateX(-10px);
}

.project-description-left:hover {
    cursor: default;
    transition: ease-in-out 0.2s;
    transform: translateX(10px);
}

.project-tools {
    font-size: 16px;
    margin-bottom: 1em;
}

.tools-left {
    max-width: 25rem;
}

.project-icons {
    font-size: 26px;
    transition: 0.3s;
    color: var(--icon-project-color);
    margin-left: 10px;
}

.project-icons-left {
    font-size: 26px;
    transition: 0.3s;
    color: var(--icon-project-color);
    margin-right: 10px;
}

.project-icons:hover {
    filter: invert(46%) sepia(69%) saturate(2512%) hue-rotate(204deg) brightness(96%) contrast(95%);
    transition: 0.3s;
}

.project-icons-left:hover {
    filter: invert(46%) sepia(69%) saturate(2512%) hue-rotate(204deg) brightness(96%) contrast(95%);
    transition: 0.3s;
}

/* Autres Projets */

.sliderimg{
    margin-top: 1.5em;
    /* width: 250px; */
    height: 250px;
    object-fit: cover;
    border: 1px solid #afabab;
    border-radius: 3px;
}

.one-item {
    /* display: flex; */
    flex-direction: column;
    justify-content: space-around;
}

.one-item:hover {
    cursor: pointer;
}

.info-item {
    font-size: 18px;
    max-width: 250px;
    text-align: center;
    margin-left: 20px;
}

.other-projects {
    margin-top: 4em;
}

.other-projects h4 {
    text-align: center;
}

.container-other-projects {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
}

.row-1, .row-2, .row-3, .row-4 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.one-other-project {
    border-left: 3px var(--border-color-other-project) solid;
    border-bottom: 3px var(--border-color-other-project) solid;
    border-radius: 3px;
    padding: 10px;
    margin: 10px;
    transition: 0.2s ease-in-out;
}

.one-other-project:hover {
    cursor: pointer;
    opacity: 0.7;
}

.one-other-projet a:visited {
    text-decoration: none;
    background-color: red;
}

.one-other-project img {
    width: 350px;
}

.one-other-project-info {
    font-size: 16px;
    text-decoration: none;
    color: #000000;
    max-width: 250px;
}

.see-more {
    margin-top: 2em;
}

.see-more-btn {
    border: none;
    background-color: #ecebebcb;
    border-radius: 5px;
    padding: 10px 25px 10px 25px;
    width: 150px;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    transition: 0.150s;
}

.see-more-btn:hover {
    cursor: pointer;
    background-color: rgb(76, 128, 240);
    color: #ffffff;
}

/* Projet Details */

.back-btn {
    color: var(--text-color);
    transition: 0.150s;
}

.back-btn:hover {
    color: var(--blue-color);
}

.arrow-icon {
    font-size: 2em;
    margin-bottom: 2em;
}

.projet-details {
    margin-top: 24vh;
    font-size: large;
}

.details-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.details-role-project {
    margin-bottom: 2rem;
    max-width: 70vh;
}

.details-role-title {
    margin-bottom: 3px;
    margin-top: 1rem;
    /* opacity: 0.7; */
    color: rgb(76, 128, 240);
}

.details-role-content {
    margin-bottom: 1rem;
}

.details-role-stack {
    margin-bottom: 2px;
}

.details-site {
    margin-top: 2rem;
}

.site-link {
    text-decoration: none;
    color: inherit;
    background-color: rgb(130, 163, 234);
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.site-link:hover {
    background-color: rgb(37, 40, 47);
    color: white; /* You can change this to the desired color on hover */
}

.details-pictures {
    display: flex;
    flex-direction: column;
}

.details-pictures img{
    margin-top: 3rem;
    border-radius: 1.6rem;
}

/* Contact */

.contact {
    text-align: center;
}

.contact h5 {
    font-weight: 100;
    font-size: 22px;
    margin-bottom: 1em;
}

.contact-btn {
    border: none;
    background-color: #ecebebcb;
    border-radius: 5px;
    padding: 10px 25px 10px 25px;
    margin: 0 5px 2em 5px;
    transition: 0.150s;
}

.contact-btn:hover {
    cursor: pointer;
    background-color: rgb(76, 128, 240);
}

.contact-icons {
    color: #000000;
    font-size: 2em;
}

.contact-btn:hover > .contact-icons {
    color: white;
}


/* Footer */

.footer {
    text-align: center;
    margin: 1em;
}

.email {
    font-size: 0.8em;
    margin-bottom: 1.5em;
}

/* About */

.about {
    margin-bottom: 2em;
}

.about-me {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
}

.about-me p {
    max-width: 800px;
    font-size: 20px;
    text-align: justify;
    line-height: 150%;
    margin-bottom: 1em;
}

.important-words {
    color: rgb(76, 128, 240);
}

.clip {
    clip-path: circle(44% at 50% 50%);
    height: 170px;
    margin-top: 1em;
}

.info-pic {
    max-width: 25em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5em;
}

.wrapper-picture {
    /* border-radius: 50%; */
    /* -webkit-box-shadow: inset 0px 0px 8px 8px rgba(255,255,255,1);
    -moz-box-shadow: inset 0px 0px 8px 8px rgba(255,255,255,1); */
    box-shadow: inset 0px 0px 4px 4px rgb(255, 255, 255);
    margin-top: 1em;
    min-height: 205px;
    min-width: 240px;
    background-image: url(./../images/profil.webp);
    background-size: contain;
    background-repeat: no-repeat;
}

/* BIG SCREENS */

    /* @media screen and (min-width: 1300px) {
        .projet-details {
            max-width: 1150px;
        }
    } */

/* @media screen and (min-width: 1300px) {
    body {
        max-width: 1150px;
    }
} */

/* @media screen and (min-width: 1500px) {
    body {
        max-width: 1250px;
    }
} */

@media screen and (min-width: 1700px) {
    body {
        max-width: 1150px;
    }

    header {
        max-width: 1150px;
    }

    .intro {
        margin-left: 17vw;
    }
}

@media screen and (min-width: 1900px) {
    body {
        max-width: 1250px;
    }

    header {
        max-width: 1250px;
    }

    .intro {
        margin-left: 19vw;
    }

    .home {
        padding: 50px;
    }
}


/* MOBILE */
@media screen and (max-width: 1000px) {

    /* Project Details */
    .projet-details {
        margin-top: 20vh;
    }

    .details-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}


@media only screen and (max-width: 830px) {

    /* Home */
    .one-project img, .one-project-left img {
        width: 400px;
    }

    .project-info {
        left: 30%;
    }

    .project-info-left {
        right: 30%;
    }

    .nav {
        font-size: 18px;
    }

    .one-other-project img {
        width: 300px;
    }

    .row-1, .row-2, .row-3, .row-4 {
        display: flex;
        flex-direction: column;
    }

    .one-other-project {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .one-other-project img {
        max-width: 300px;
    }
    
}

@media only screen and (max-width: 780px) {
    .one-project img, .one-project-left img {
        width: 350px;
    }
}

@media only screen and (max-width: 730px) {
    .one-project, .one-project-left {
        display: flex;
        flex-direction: column-reverse;
        position: static;
        margin: 0;
        transform: none;
    }

    .one-project-left {
        margin-top: 3em;
    }

    .one-project:hover .project-description {
        transform: none;
    }

    .one-project-left:hover .project-description-left {
        transform: none;
    }

    .project-thumb {
        overflow: visible;
    }

    .vedette-left-img img {
        width: 400px;
        height:auto;
        border: 1px #dadadacb solid;
        border-radius: 3px;
    }

    .overlay {
        background-color: transparent ;
        transition: 0.2s;
        height: 100%;
        width: 100%;
        /* position: static; */
    }

    .project-info, .project-info-left {
        position: static;
        text-align: center;
        align-items: center;
    }

    .project-title {
        text-align: center;
        margin-right: auto;
    }

    .project-title-left {
        text-align: center;
        margin-left: auto;
    }

    .project-description, .project-description-left {
        padding: 1em;
        font-size: 16px;
        transform: none;
        max-width: 450px;
    }

    .project-links {
        margin-bottom: 1em;
    }
}

@media only screen and (max-width: 700px) {

    .section {
        margin-bottom: 2em;
    }

    /* Header Mobile */
    header {
        padding: 5px 15px 10px 10px;
    }

    .nav {
        padding: 5px;
        font-size: 16px;
    }

    .nav>li, .nav-link {
        padding: 5px; 
        margin: 5px;
    }


    /* Intro Mobile */
    .intro {
        margin-top: 30vh;
        margin-bottom: 30vh;
        padding-left: 0;
    }

    /* Skill Mobile */
    .skill-item {
        border-right: 2px solid rgb(76, 128, 240);
    }

    .skill-item ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        list-style-type: none;
    }

    .skill-container {
        display: flex;
        flex-direction: row;
    }
 
    .skill-item li {
        font-size: 16px;
        padding: 15px 35px 15px 35px;
        line-height: 150%;
        border-radius: 0;
        text-align: center;   
    }
    
    
    .skill-item li:hover {
        background-color: #ecebebcb;
        border-radius: 0;
        transition:  0.2s;
        cursor: pointer;
    }

    .skill-details {
        border-top: none;
        flex-wrap: wrap;
    }

    /* About Mobile */

    .about {
        padding: 0;
    }

    .about h2 {
        padding: 20px;
        margin: 0;
    }
    
    .about-me {
        flex-direction: column-reverse;
        margin-left: 1.5em;
        margin-right: 1.5em;
    }

    .about-me p {
        font-size: 18px;
    }

    .wrapper-picture {
        margin: 0;
    }

}

@media only screen and (max-width: 600px) { 

    /* Other Projects */
    /* .row-1, .row-2, .row-3, .row-4 {
        display: flex;
        flex-direction: column;
    }

    .one-other-project {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    } */
}

/* Mobile Small */
@media only screen and (max-width: 410px) {
    header h2 {
        /* font-size: 18px; */
    }

    .nav {
        /* font-size: 12px; */
        font-weight: 500;
    }

    .about-me {
        margin-left: 1.5em;
        margin-right: 1.5em;
    }
}

/* iPad Pro */
@media only screen and (max-width: 1100px) and (min-height: 1200px) {
    header {
        font-size: 24px;
    }

    header h2 {
        font-size: 36px;
    }

    .intro {
        font-size: 250%;
    }

    .section {
        font-size: 200%;
    }

    .skill-item li {
        font-size: 26px;
    }

    .one-skill {
        font-size: 26px
    }

    .project-description {
        font-size: 18px;
    }

    .project-info p {
        font-size: 24px;
    }

    .about-me p {
        font-size: 90%;
    }

    .wrapper-picture {
        min-height: 250px;
        min-width: 210px;
    }

    .contact h5 {
        font-size: 120%;
    }

    .contact button {
        font-size: 24px;
    }

    .about-me {
        margin-left: 1.5em;
    }
}


/* CARTE */

.separator {
    height: 2px;
    width:500px;
    background-color: rgb(90, 108, 134);
    /* background-color: rgb(95, 92, 93); */
    /* background-color: rgb(76, 128, 240); */
    margin: 2rem 0 2rem 0
}

.carte-info {
    font-size: 0.7em;
    margin-bottom: 5px;
}

.carte-icon {
    margin-right: 1rem;
}